import '../css/order.css';
import { useState } from 'react';

import Orders from "./Orders";

const Quality = ({ notify }) => {
    const [View, setView] = useState('requests');
    const [Template, setTemplate] = useState('template1');

    const handleViewClick = (view) => {
        setView(view);
    };

    const [ClientName, setClientName] = useState('');
    const [BookName, setBookName] = useState('');
    const [DeliveryDate, setDeliveryDate] = useState('');
    const [DeliveryLocation, setDeliveryLocation] = useState('');
    const [TrackingLink, setTrackingLink] = useState('');

    const copyClick = (event) => {
      const content = event.target.textContent;
      navigator.clipboard.writeText(content);
      notify('Data Copied')
    };

    return (
        <>
            <div className="main-infos">
                <div className={`main-info ${View === 'requests' ? 'active' : ''}`} onClick={() => handleViewClick('requests')}><i className="fas fa-question-circle"></i>Requests</div>
                <div className={`main-info ${View === 'feedback' ? 'active' : ''}`} onClick={() => handleViewClick('feedback')}><i className="fas fa-info-circle"></i>Feedback</div>
                <div className={`main-info ${View === 'az_claims' ? 'active' : ''}`} onClick={() => handleViewClick('az_claims')}><i className="fas fa-headset"></i>AZ Claims</div>
                <div className={`main-info ${View === 'templates' ? 'active' : ''}`} onClick={() => handleViewClick('templates')}><i className="fas fa-headset"></i>Templates</div>
            </div>
            {View === 'requests' && (<Orders showFilters={false} status='answers_needed' notify={notify}/>)}
            {View === 'feedback' && (<Orders showFilters={false} status='feedback_to_request' notify={notify}/>)}
            {View === 'az_claims' && (<Orders showFilters={false} status='az_claims' notify={notify}/>)}
            {View === 'templates' && (
                <>
                <button onClick={() => setTemplate('template1')}>Cancel - No Sourcing</button>    
                <button onClick={() => setTemplate('template2')}>Refund - Dummy Shipping</button>
                <button onClick={() => setTemplate('template3')}>Delivery Details</button>
                {Template === 'template1' && (
                <div>
                    <input
                        type="text"
                        placeholder="Client Name"
                        value={ClientName}
                        onChange={(e) => setClientName(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Book Name"
                        value={BookName}
                        onChange={(e) => setBookName(e.target.value)}
                    />
                    <div className='template' onClick = {copyClick}>
                        {`Dear ${ClientName === '' ? '{{Client Name}}' : ClientName},

I hope this message finds you well. We are writing to you regarding your recent order with us.

We regret to inform you that due to an unexpectedly high demand for the book you ordered, "${BookName === '' ? '{{Book Name}}' : BookName}" we are currently out of stock and, unfortunately, will not be able to fulfill your order at this time. This is a rare situation that we did not anticipate, and we understand the inconvenience this may cause.

As a result, we need to cancel your current order. We sincerely apologize for any disappointment or inconvenience this may have caused you. We are working diligently to replenish our stock and would be more than happy to notify you as soon as "${BookName === '' ? '{{Book Name}}' : BookName}" becomes available again.

If you would like to be informed about the availability of the book, please reply to this email or contact our customer service team, and we will ensure you are among the first to know once it's back in stock. Alternatively, if there is another book or product you are interested in, our team is here to assist you in finding the perfect item.

We truly value your understanding and patience in this matter and look forward to serving you again in the future.

Warm regards,

Joe at Epytis`}
                    </div>
                </div>
                )}
                {Template === 'template2' && (
                <div>
                    <input
                        type="text"
                        placeholder="Client Name"
                        value={ClientName}
                        onChange={(e) => setClientName(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Book Name" 
                        value={BookName}
                        onChange={(e) => setBookName(e.target.value)}
                    />
                    <div className='template' onClick = {copyClick}>
                        {`Dear ${ClientName === '' ? '{{Client Name}}' : ClientName},  

We sincerely apologize for the delay in your order. This is absolutely not the experience we want for our customers.

We will immediately process a full refund to your credit card. Additionally, we will reach out to our carrier to understand what happened with the shipment.

We truly regret the inconvenience and appreciate your patience. Please let us know if there’s anything else we can do for you.

Best regards,
Joe
Epytis Customer Support`}
                    </div>
                </div>
                )}
                {Template === 'template3' && (      
                <div>
                    <input
                        type="text"
                        placeholder="Client Name"
                        value={ClientName}
                        onChange={(e) => setClientName(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Book Name"
                        value={BookName}
                        onChange={(e) => setBookName(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Delivery Location"
                        value={DeliveryLocation}
                        onChange={(e) => setDeliveryLocation(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Delivery Date"
                        value={DeliveryDate}
                        onChange={(e) => setDeliveryDate(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Tracking Link"
                        value={TrackingLink}
                        onChange={(e) => setTrackingLink(e.target.value)}
                    />
                    
                    
                    
                    <div className='template' onClick = {copyClick}>
                        {`Dear ${ClientName === '' ? '{{Client Name}}' : ClientName},

Thank you for reaching out. 

According to the tracking information available at the following link: 

${TrackingLink === '' ? '{{Tracking Link}}' : TrackingLink}

Your  package was delivered ${DeliveryLocation === '' ? '{{Delivery Location}}' : DeliveryLocation} on ${DeliveryDate === '' ? '{{Delivery Date}}' : DeliveryDate}.

If you are unable to locate the package, we recommend checking with neighbors, your building's reception, or contacting your local USPS office for additional details.

Please let us know if you are unable to locate the package, and we’ll do our best to assist you further. 

Best regards,
Joe at Epytis`}
                    </div>
                </div>
                )}
            </>
            )}
        </>
    )
}

export default Quality;
