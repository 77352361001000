import React from 'react'
import usePost from '../api/usePost';

const RouteLine = ({refresh, notify, setRefresh, id, sourcing, destination, segment, status, algorithm, deployed, products_available, listings, parent_id,sales_count, products_with_sales, showSubroutes, setShowSubroutes, deployment_percent, to_deploy, average_price, last_update, average_margin}) => {
  const { postData, error } = usePost();

  const toggleRouteDeploy = async () => {
    const data = {route_id: id}
    const response = await postData('/toggle_route_deploy', data);
    console.log('Response:', response);
    console.log('Error:', error);
    if (response) {
        setRefresh(!refresh)
        notify(response.message)
    }
  }
  
  return (
    <>


      {(segment === 'all' || showSubroutes.includes(parent_id)) && (
        <div className="table-row" data-id={id}>
          <div style={{fontWeight: 'bold'}} className="small">{showSubroutes.includes(id) && segment === 'all'  && sourcing !== 'Inventory' &&  <i onClick={() => setShowSubroutes(showSubroutes.filter(route => route !== id))} className="fas fa-minus-square"></i>}{!showSubroutes.includes(id) && segment === 'all' &&  sourcing !== 'Inventory' &&  <i onClick={() => setShowSubroutes([...showSubroutes, id])} className='fas fa-plus-square'></i>}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{sourcing}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{destination}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{segment}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{status}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{algorithm}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{deployed ? <i className="fas fa-check-circle green"></i> : <i className="fas fa-times-circle red"></i>}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{(deployment_percent * 100).toFixed(0)}%</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{listings} ({listings && products_available && products_available > 0 && (listings/products_available * 100).toFixed(1)}%)</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">${average_price}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">${average_margin}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{last_update}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{sales_count}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{products_with_sales}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small"><i onClick={() => toggleRouteDeploy()} className={`fas ${to_deploy ? 'green fa-toggle-on' : 'red fa-toggle-off'}`} ></i></div>
        </div>
      )}
    </>
  )
}

export default RouteLine